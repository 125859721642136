import {
	convertToHalfWidth,
	convertToFullWidth,
	checkFullWidthContent,
	formatPostalCode,
} from '@/util/formatNumber';
import CommonConst from '@/util/const';
import zipAddressService from '@/services/zip_address.service';
import ProvinceService from '@/services/prefecture_jp.service';

export default {
	data() {
		return {
			errorNoteMsg: '',
		};
	},
	watch: {
		async 'model.postcodeAddressJapan'(newPostcode, oldPostcode) {
			// console.log(`new ${newPostcode} and old ${oldPostcode}`);
			newPostcode = convertToHalfWidth(newPostcode);
			oldPostcode = convertToHalfWidth(oldPostcode);

			if (newPostcode == null) {
				return;
			}

			// Check if input contains non-numeric characters
			if (/[^0-9]/.test(newPostcode)) {
				// Replace all non-numeric characters to empty
				newPostcode = newPostcode.replace(/[^0-9]/g, '');
				oldPostcode = oldPostcode.replace(/[^0-9]/g, '');
				this.model.postcodeAddressJapan = newPostcode;
			}
			// Check if input have more than 7 characters
			if (newPostcode.length > CommonConst.POST_CODE_LENGTH - 1) {
				this.model.postcodeAddressJapan = oldPostcode;

				// Get address when have enough number
				if (oldPostcode.length === CommonConst.POST_CODE_LENGTH - 1) {
					let formattedPostcode = oldPostcode.replace(/(\d{3})(\d{4})/, '$1-$2');
					await this.getAddressPostcode(formattedPostcode);
				}
			}
			// Get address when have 7 numbers
			else if (newPostcode.length === CommonConst.POST_CODE_LENGTH - 1) {
				this.model.postcodeAddressJapan = newPostcode;
				let formattedPostcode = newPostcode.replace(/(\d{3})(\d{4})/, '$1-$2');
				await this.getAddressPostcode(formattedPostcode);
			}
			// Reset address when not valid postcode
			else {
				console.log("vao day neene");
				this.provinceSelect = {};
				// this.model.address = '';
				this.model.address = this.model.address ? this.model.address : '';

			}
		},
	},
	methods: {
		convertToHalfWidth,
		formatPostalCode,
		findProvinceSelect(provinceName) {
			let provinceChosen = {};
			if (this.provinceOptions) {
				provinceChosen = this.provinceOptions.find(
					(province) => province.label === provinceName
				);
			}

			return provinceChosen;
		},

		async getAddressPostcode(formatPostcode) {
			try {
				const response = await zipAddressService.get(formatPostcode);
				const address = response.data;
				this.provinceSelect = this.findProvinceSelect(address.pref);
				// Handle for edit page, if it already has value
				this.model.address = this.model.address ? this.model.address : address.fullAddress;
				this.errorNoteMsg = '';
			} catch (error) {
				console.log(error);
			}
		},

		onBlurPostcode(event) {
			if (checkFullWidthContent(event.target.value)) {
				// this.errorNoteMsg = 'You are typing fullwidth!';
				this.errorNoteMsg = '郵便番号が正しくありません';
			} else {
				this.errorNoteMsg = '';
			}
		},

		async getProvince() {
			try {
				let response = await ProvinceService.get();
				return response.data.response.prefecture;
			} catch (error) {
				throw error;
			}
		},

		async filterProvinces() {
			let provinces = await this.getProvince();
			let provinceOptions = [];
			provinces.map((province, idx) => {
				let provinceInfo = {
					value: idx,
					label: province,
				};
				provinceOptions.push(provinceInfo);
			});
			this.provinceOptions = [{ value: '', label: '選択なし' }, ...provinceOptions];
			return provinces;
		},
	},
	async mounted() {
		await this.filterProvinces();
	},
};
