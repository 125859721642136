<template>
	<div>
		<b-modal
			:id="notificationModalId"
			hide-footer
			hide-header
			centered
			:body-class="{
				notiModal__body: !singleBtn,
				'notiModal__body --plainModal': singleBtn,
			}"
			content-class="notiModal__content"
			dialog-class="notiModal__dialog"
			@hide="onHide"
			no-close-on-esc
		>
			<div
				class="notiModal__notiContent"
				:class="{
					'--plainModal': singleBtn,
				}"
			>
				{{ notiContent }}
			</div>
			<div v-if="!singleBtn" class="notiModal__buttonContainer">
				<button class="notiModal__acceptBtn" @click="onClick(true)">はい</button>
				<button class="notiModal__cancelBtn" @click="onClick(false)">いいえ</button>
			</div>
			<div v-if="singleBtn" class="notiModal__buttonContainer">
				<div class="notiModal__closeBtn" @click="onClick(false)"></div>
			</div>
		</b-modal>
	</div>
</template>

<script>
export default {
	props: {
		notiContent: {
			type: String,
		},
		notificationModalId: {
			type: String,
		},
		singleBtn: {
			type: Boolean,
			default: false,
		},
		redirect: {
			type: String,
			default: '',
		},
	},
	methods: {
		onClick(data) {
			if (data) {
				this.$emit('on-click');
				this.$bvModal.hide(`${this.notificationModalId}`);
			} else {
				this.$bvModal.hide(`${this.notificationModalId}`);
				this.$emit('on-decline');
			}
		},
		onHide() {
			if (this.redirect) {
				this.$router.push(this.redirect);
			}
		},
	},
};
</script>

<style lang="scss" src="./NotificaitonModal.scss"></style>
