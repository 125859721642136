// The Unicode offset value between full - width and half - width characters for digits in the Unicode standard
const UNICODE_OFFSET = 65248;

// Regex check fullwidth characters
const FULLWIDTH_REGEX = /[\uFF01-\uFF5E\u3040-\u309F\u30A0-\u30FF]/;

// Regex fullwidth digits
const FULLWIDTH_DIGIT_REGEX = /[０-９]/g;

// Regex halfwidth digits
const HALFWIDTH_DIGIT_REGEX = /[0-9]/g;

// Regex postcode
const POSTAL_CODE_REGEX = /(\d{3})(\d{4})/;

// Format Text-Text
const POSTAL_CODE_FORMAT = '$1-$2';

export const convertToHalfWidth = (str) => {
	let inputValue = '';
	if (str) {
		str = String(str);
		// Replace full-width digits with half-width digits
		inputValue = str.replace(FULLWIDTH_DIGIT_REGEX, function (s) {
			return String.fromCharCode(s.charCodeAt(0) - UNICODE_OFFSET);
		});
	}
	return inputValue;
};

export const convertToFullWidth = (str) => {
	let inputValue = '';
	if (str) {
		str = String(str);
		// Replace half-width digits with full-width digits
		inputValue = str.replace(HALFWIDTH_DIGIT_REGEX, function (s) {
			return String.fromCharCode(s.charCodeAt(0) + UNICODE_OFFSET);
		});
	}
	return inputValue;
};

export const checkFullWidthContent = (content) => {
	// Regex check if input contains fullwidth characters
	const isFullWidthOrKanji = FULLWIDTH_REGEX.test(content);
	if (isFullWidthOrKanji) {
		return true;
	} else {
		return false;
	}
};

/**
 *
 * @param {str} str postcode string
 * @returns postcode after formatted xxx-xxxx
 */
export const formatPostalCode = (str) => {
	if (str) {
		str = String(str);
		return str.replace(POSTAL_CODE_REGEX, POSTAL_CODE_FORMAT);
	}
};
