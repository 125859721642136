import zipAddressService from '@/services/zip_address.service';
import CommonConst from '@/util/const';
import {
	onInputNumberOnly,
	checkFullWidthContent,
	filterFullWidthNumber,
} from '@/util/inputNumber';

const HYPHEN_INDEX = 3;

export default {
	data() {
		return {};
	},
	methods: {
		onInputNumberOnly,
		checkFullWidthContent,
		filterFullWidthNumber,
		onKeyDownPostalCode(event) {
			const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
			const isNumericKey = /\d/.test(event.key);
			const isAllowedKey = allowedKeys.includes(event.key);
			if (!isNumericKey && !isAllowedKey) {
				event.preventDefault();
			}
		},
		/**
		 *
		 * @param {String} postalCode postalCode
		 */
		addHyphenPostalCode(postalCode) {
			const postalCodeLength = postalCode.length;
			const tempPostalCode = postalCode.replaceAll('-', '');

			if (postalCodeLength > HYPHEN_INDEX) {
				return (
					tempPostalCode.slice(0, HYPHEN_INDEX) +
					'-' +
					tempPostalCode.slice(HYPHEN_INDEX)
				);
			}
			return postalCode;
		},

		async onPostalCodeInput(value) {
			// Check haft width
			const halfWidthPostCode = checkFullWidthContent(value)
				? filterFullWidthNumber(value)
				: value;

			let hyphenValue = this.addHyphenPostalCode(halfWidthPostCode);

			// add hyphen
			let slicedTempValue = hyphenValue.includes('-')
				? hyphenValue.slice(0, CommonConst.POST_CODE_LENGTH)
				: hyphenValue.slice(0, CommonConst.POST_CODE_LENGTH - 1);

			// Prevent postal code over limit length
			if (slicedTempValue.length >= CommonConst.POST_CODE_LENGTH - 1) {
				// get address
				const response = await zipAddressService.get(slicedTempValue);
				const address = response.data;
				this.model.address = address.fullAddress;
			} else {
				this.model.address = '';
			}

			setTimeout(() => {
				this.model.postcode = slicedTempValue;
			}, 0);
		},
	},
};
