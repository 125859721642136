import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

const apiUrl = 'https://api.zipaddress.net/';

axios.defaults.headers = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};

const zipAddressService = {
	/**
	 * Get Address form ZIP Code
	 * @param {String} zipCode Japanese ZIP Code
	 * @returns Address object
	 */
	get(zipCode) {
		try {
			return axios.get(apiUrl, {
				params: { zipcode: zipCode },
				adapter: jsonpAdapter,
			});
		} catch (error) {
			throw new Error(`ZipAddressService: ${error}`);
		}
	},
};

export default zipAddressService;
